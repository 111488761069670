// Open Sans
@import 'https://fonts.googleapis.com/css?family=Open+Sans:400,600,700';

// wri-icons
@font-face {
  font-family: wri-icons;
  font-style: normal;
  font-display: block;
  text-rendering: optimizelegibility;
  src: url('https://cdn.bfldr.com/NDQASMJ1/at/fc2ffppfwwbx325ff377s68/wri-icons-2044.otf')
      format('opentype'),
    url('https://cdn.bfldr.com/NDQASMJ1/at/wm3rxm795m3hn39hsfc7g5/wri-icons-2044.woff')
      format('woff'),
    url('https://cdn.bfldr.com/NDQASMJ1/at/mj8x5x9ss7vxvs5qgkxqr/wri-icons-2044.ttf')
      format('truetype'),
    url('https://cdn.bfldr.com/NDQASMJ1/at/4gj5fm8qf6hmj4n2g6gsbx/wri-icons-2044.svg')
      format('svg');
}

@font-face {
  font-family: 'Roc Grotesk';
  src: url('https://cdn.bfldr.com/NDQASMJ1/at/r3b5k7qt9gc6w49jcrqvvx/RocGroteskCond-Medium.otf');
  font-weight: 500;
}

@font-face {
  font-family: 'Roc Grotesk';
  src: url('https://cdn.bfldr.com/NDQASMJ1/at/rmj7gvg8wkbrg8s87z2rszjc/RocGroteskCond-Bold.otf');
  font-weight: 600;
}

@font-face {
  font-family: 'Roc Grotesk';
  src: url('https://cdn.bfldr.com/NDQASMJ1/at/rhfv6znz9ntcxs9vv3963j/RocGroteskCond-ExtraBold.otf');
  font-weight: 700;
}
